<template>
  <div class="check_text">
    <label>
      <input v-model="selected" type="checkbox" true-value="Y" false-value="N" @change="handleUpdateFollow">
      <span>팔로우</span>
      <span class="toggle">팔로잉</span>
    </label>
  </div>
</template>

<script setup lang="ts">
import useContentInterest from '~/modules/content/composables/interest'
import useContentNavigator from '~/modules/content/composables/navigate'

interface IData {
  id: number
  followYn: string
  postSeq?: number
}

const props = defineProps<IData>()

const emit = defineEmits<{(e: 'update', item: IData): void}>()

const route = useRoute()
const config = useRuntimeConfig()
const { navigateToInterestFollowing } = useContentNavigator()
const { checkedLogin, follow } = useContentInterest()
const { navigateToLogin } = useMemberNavigator()

const confirm: any = inject('confirm')
const snackbar: any = inject('snackbar')

const selected = ref('N')
onMounted(() => {
  selected.value = props.followYn ?? 'N'
})

watch(() => props.followYn, (followYn) => {
  selected.value = followYn ?? 'N'
})

const handleUpdateFollow = () => {
  checkedLogin(
    async () => {
      await follow(props.id, selected.value, props.postSeq)

      if (selected.value === 'Y') {
        snackbar.info('팔로잉 목록에 추가되었습니다', '팔로잉 목록 보기', () => {
          navigateToInterestFollowing()
        })
      }

      emit('update', { id: props.id, followYn: selected.value })
    },
    () => {
      selected.value = selected.value === 'Y' ? 'N' : 'Y'

      confirm.open({
        title: '로그인이 필요합니다<br />로그인 페이지로 이동할까요?',
        confirm: () => {
          navigateToLogin({ query: { redirectUrl: `${config.public.SHOP_HOST}${route.fullPath}` } })
        }
      })
    }
  )
}

</script>

<style scoped>
.check_text {width: auto; text-align: center;  display:inline-block;}
.check_text input[type="checkbox"] {width:0px; height:0px; overflow:hidden; border:0; opacity:0; -webkit-appearance:none; -moz-appearance:none; appearance:none; position: absolute;}
.check_text span {display: block; width:auto; height: 1.875rem; line-height: 1.744rem; padding:0 8px; border: 1px solid var( --color-button-line-default); border-radius:4px; box-sizing: border-box; font-size: var(--font-size-7); color: var(--color-button-text-default); word-break: keep-all;}
.check_text span.toggle{display:none;}
.check_text input[type="checkbox"]:checked + span {display:none;}
.check_text input[type="checkbox"]:checked + span + .toggle {display:block; background-color:var(--color-button-solid-hover); color:var(--color-text-hover); border-color:var(--color-button-solid-hover);}
.check_text.small span {height: 1.375rem; line-height: 1.25rem; border: 1px solid var( --color-button-small-line-default);}
.check_text.small input:disabled + span{background:var(--color-button-solid-disabled); color:var(--color-button-text-disabled); border-color:var(--color-button-line-default);}

.check_text.fc-white span{color:var(--color-background-default);}

@media screen and (max-width: 768px) {
  .check_text.detail{display:none;}
}
</style>
